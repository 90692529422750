<script>
import { AgGridVue } from 'ag-grid-vue'
import AG_GRID_LOCALE_FR from '@/assets/ag-grid.locale.fr.json'
import AvatarCellRenderer from './AvatarCellRenderer.vue'
import BooleanCellRenderer from './BooleanCellRenderer.vue'
import BooleanFilterCellRenderer from './BooleanFilterCellRenderer.vue'
import ImageCellRenderer from './ImageCellRenderer.vue'
import RatingCellRenderer from './RatingCellRenderer.vue'
import TagCellRenderer from './TagCellRenderer.vue'
import TagFilterCellRenderer from './TagFilterCellRenderer.vue'
import FilesCellRenderer from './FilesCellRenderer.vue'
import IconCellRenderer from './IconCellRenderer.vue'
import DateCellRenderer from './DateCellRenderer.vue'
import ArrayCellRenderer from './ArrayCellRenderer.vue'
import TotalRowCountStatusBar from '../../pages/Users/TotalRowCountStatusBar.vue'

export default {
  components: {
    'ag-grid-vue': AgGridVue,
    AvatarCellRenderer, //eslint-disable-line
    BooleanCellRenderer, // eslint-disable-line
    BooleanFilterCellRenderer, // eslint-disable-line
    ImageCellRenderer, // eslint-disable-line
    RatingCellRenderer, //eslint-disable-line
    TagCellRenderer, // eslint-disable-line
    TagFilterCellRenderer, // eslint-disable-line
    FilesCellRenderer, // eslint-disable-line
    IconCellRenderer, // eslint-disable-line
    DateCellRenderer, // eslint-disable-line
    ArrayCellRenderer, // eslint-disable-line
    TotalRowCountStatusBar // eslint-disable-line
  },
  props: [
    'options',
    'gridData',
    'showAllDetails'
  ],
  data () {
    return {
      loading: false,
      gridApi: null,
      columnApi: null,
      gridOptions: null,
      gridOptionsDefault: {
        rowModelType: 'clientSide',
        localeText: AG_GRID_LOCALE_FR,
        columnDefs: [],
        defaultColDef: {
          resizable: true,
          floatingFilter: true
        },
        statusBar: {
          statusPanels: [
            {
              statusPanel: 'agTotalAndFilteredRowCountComponent',
              align: 'left'
            }
          ]
        },
        columnTypes: {
          dateColumnFilterable: {
            headerName: 'Date',
            filter: 'agDateColumnFilter',
            filterParams: {
              maxNumConditions: 1,
              comparator: (filterLocalDateAtMidnight, cellValue) => {
                const cellDate = new Date(cellValue).setHours(0, 0, 0, 0)
                filterLocalDateAtMidnight.setHours(0, 0, 0, 0)

                if (cellDate < filterLocalDateAtMidnight) {
                  return -1
                } else if (cellDate > filterLocalDateAtMidnight) {
                  return 1
                }
                return 0
              }
            }
          }
        }
      }
    }
  },
  watch: {
    gridData: {
      handler () {
        this.gridApi.redrawRows()
      },
      deep: true
    },
    'options.columnDefs': {
      handler () {
        this.gridOptions.api.setColumnDefs(this.options.columnDefs)
        this.gridApi.sizeColumnsToFit()
      }
    }
  },
  beforeDestroy () {
    this.gridApi = null
    window.removeEventListener('resize', this.fitColumns)
  },
  created () {
    this.gridOptions = {
      ...this.gridOptionsDefault,
      ...this.options
    }
  },
  methods: {
    fitColumns () {
      this.gridApi.sizeColumnsToFit()
    },
    onGridReady (params) {
      this.gridApi = params.api
      this.columnApi = params.columnApi
      window.addEventListener('resize', this.fitColumns)
      this.$emit('gridReady', params)
    },
    onFirstDataRendered (params) {
      // this.columnApi.autoSizeAllColumns()
      this.gridApi.sizeColumnsToFit()
      if (this.gridApi && this.showAllDetails) {
        this.gridApi.forEachNode(node => {
          node.setExpanded(true)
        })
      }
    }
  }
}
</script>

<template>
  <ag-grid-vue
    style="height: 65vh;"
    class="ag-theme-balham w-full px-2"
    :gridOptions="gridOptions"
    :rowData="gridData"
    @grid-ready="onGridReady"
    @first-data-rendered="onFirstDataRendered"
    @selectionChanged="$emit('selectionChanged', gridApi.getSelectedRows())"
    @rowDoubleClicked="$event => $emit('rowDoubleClicked', $event)"
  />
</template>
